import React from 'react'
import {
    Button,
    Stack,
    Image,
    useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormInputControl from '../input/FormInputControl';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../db/config'
function LoginComponent() {
    const navigate = useNavigate();
    const toast = useToast({
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
        duration: 5000,
    });
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}

            validationSchema={Yup.object({
                email: Yup.string()
                    .email('El Email es invalido')
                    .required('La contraseña es requerida'),
                password: Yup.string()
                    .required('La contraseña es requerida'),
            })}

            onSubmit={(values, actions) => {
                signInWithEmailAndPassword(auth, values.email, values.password).then(async (user) => {
                    localStorage.setItem('token', (await user.user.getIdToken()).toString());
                    navigate('/panel/inicio')
                    toast({
                        title: 'Bienvenido',
                        description: 'Has iniciado sesión correctamente',
                        status: 'success',
                    })
                    actions.setSubmitting(false);
                }).catch(error => {
                    actions.setSubmitting(false);
                    toast({
                        title: 'Error',
                        description: error.message,
                        status: 'error',
                    })
                });
            }}
        >
            {(formik) => (
                <Stack spacing={4} w={'full'} maxW={'md'}>
                    <Image src={'/img/logo.png'} alt={'logo'} w='200px' />
                    <FormInputControl name='email' type='email' placeholder='Pon tu email' label={'Email'} />
                    <FormInputControl name='password' type='password' placeholder='Pon tu contraseña' label={'Contraseña'} />
                    <Stack spacing={6}>
                        <Button
                            onClick={formik.handleSubmit}
                            backgroundColor={'#38D1BC'}
                            color='white'
                            _hover={{
                                backgroundColor: '#29B9A6',
                                color: 'black',
                                transition: 'all 0.2s ease-in-out',
                            }}
                            variant={'solid'}
                            type='submit'
                        >
                            Iniciar Sesión
                        </Button>
                    </Stack>
                </Stack>
            )}

        </Formik>
    )
}

export default LoginComponent