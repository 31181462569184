import { Box, Button, Center, Container, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { requests } from '../../utils/axios';
import QrReader from 'react-qr-reader'
import { Formik, Form } from 'formik';
import FormInputControl from '../../components/input/FormInputControl';
import ViajesCard from '../../components/viajes/ViajesCard';
import { usePasajeros } from '../../contexts/PasajerosContext';
import { useRef } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const alert = withReactContent(Swal)

function Viaje() {
  const toast = useToast({
    position: 'top-right',
    variant: 'left-accent',
    isClosable: true,
    duration: 5000,
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const pasajerosCon = usePasajeros();
  const [pasajerosData, setPasajerosData] = useState({
    nombre: '',
    rut: '',
    num_serie: '',
  });
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const getData = async () => {
      const res = await requests.get('/links/get/' + id, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      }).catch(err => {
        if (err.response.status === 404) {
          navigate('/viaje/error/404');
        }
      })

      if (!res) return;

      const data = res.data.data

      if (data.opened === true) return navigate('/viaje/error/opened')
      // if the travelHour is 5 minutes or less from actual time
      console.log(data.travelHour);
      const travelHour = new Date(data.travelHour)
      const actualHour = new Date()
      console.log(actualHour);
      // if (travelHour - actualHour >= 300000 || actualHour - travelHour >= 300000) return navigate('/viaje/error/tiempo')
      console.log(travelHour);
    }
    getData();
  }, [id, navigate])

  const handleScan = async data => {
    if (data) {
      const url = new URL(data);
      const params = new URLSearchParams(url.search);
      const run = params.get('RUN');
      const serial = params.get('serial');
      await requests.post('/pasajeros/get', { run }).then(res => {
        const resData = res.data.data;
        // se añadirá automaticamente a la lista de los pasajeros
        pasajerosCon.addPasajero({
          nombre: resData.nombre,
          rut: resData.rut,
          num_serie: serial,
        })
        toast({
          title: 'Añadido Correctamente',
          description: 'Continua con el siguente pasajero, aprentando el boton de "Escanear Codigo QR"',
          status: 'success',
        })
      }).catch(err => {
        if (err.response.status === 404) {
          toast({
            title: 'Pasajero no encontrado',
            description: 'El pasajero no se encuentra registrado en el sistema, tendras que ingresarlo manualmente',
            status: 'error',
          })
          setPasajerosData({
            nombre: '',
            rut: run,
            num_serie: serial,
          })
          onOpen();
        } else {
          alert(err)
          toast({
            title: 'Error',
            description: 'Ha ocurrido un error al intentar obtener los datos del pasajero',
            status: 'error',
          })
        }
      })
    } else {
      toast({
        title: 'Error',
        description: 'No se recibio data del escaneo qr, reintenta nuevamente',
        status: 'error',
      })
    }
  }

  const handleAdd = async (values) => {
    requests.post('/pasajeros/add', {
      nombre: values.nombre,
      rut: values.rut,
      num_serie: values.num_serie,
    }).then(() => {
      pasajerosCon.addPasajero({
        nombre: values.nombre,
        rut: values.rut,
        num_serie: values.num_serie,
      })
      toast({
        title: 'Pasajero agregado',
        description: 'El pasajero se ha agregado correctamente',
        status: 'success',
      })
      onClose();
    }).catch(err => {
      toast({
        title: 'Error al agregar pasajero',
        description: 'Ha ocurrido un error al agregar el pasajero, intentelo nuevamente',
        status: 'error',
      })
      console.log(err);
    })
  }

  const qrRef = useRef(null)

  const onScanFile = () => {
    qrRef.current.openImageDialog()
  }

  const handleOnErrorFile = () => {
    toast({
      title: 'Error al leer QR',
      description: 'Ha ocurrido un error al leer el QR, intentelo nuevamente',
      status: 'error',
    })
  }

  const handleSendData = async () => {
    alert.fire({
      title: 'Quieres enviar los datos?',
      text: 'Una vez enviados los datos, no podras volver a editarlos',
      showDenyButton: true,
      confirmButtonText: 'Enviar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        requests.post('/links/start/', {
          pasajeros: pasajerosCon.getPasajeros(),
          id
        }, {
        }).then(() => {
          toast({
            title: 'Viaje iniciado',
            description: 'El viaje se ha iniciado correctamente',
            status: 'success',
          })
          navigate('/viaje/success/started')
        }).catch(err => {
          toast({
            title: 'Error al iniciar el viaje',
            description: 'Error al iniciar el viaje, intentelo nuevamente',
            status: 'error',
          })
          console.log(err);
        })
      } else if (result.isDenied) {
        Swal.fire('Cancelado!', 'Tu viaje no se ha enviado.', 'info')
      }
    })
  }

  return (
    <>
      <Container size={'2xl'} textAlign='center' my={5}>
        <Heading size={'3xl'}>Viaje</Heading>
        <Box
          mt={3}
        >
          <QrReader
            ref={qrRef}
            delay={300}
            onError={handleOnErrorFile}
            onScan={handleScan}
            legacyMode
          />
          <Button onClick={onScanFile}>
            Escanear Codigo QR
          </Button>
        </Box>

        <Stack mt={3}>
          <Heading size={'xl'}>Pasajeros</Heading>
          <Center
            mt={3}
            flexDirection={'column'}
          >
            {
              pasajerosCon.getPasajeros().map((pasajero, index) => (
                <ViajesCard data={pasajero} id={index} />
              ))
            }
          </Center>
        </Stack>
        <Stack mt={3}>
          <Button
            colorScheme={'blue'}
            onClick= {handleSendData}
            isDisabled={pasajerosCon.getPasajeros().length === 0}
          >
            Iniciar viaje
          </Button>
        </Stack>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Añadir Pasajero</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={pasajerosData}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await handleAdd(values);
              setSubmitting(false);
            }}
          >
            {
              () => (
                <Form>
                  <ModalBody>
                    <FormInputControl name='nombre' type='text' placeholder='Ingresa el nombre de la persona manualmente' label={'Nombre'} />
                    <FormInputControl name='rut' type='text' placeholder='Pon tu contraseña' label={'Rut'} />
                    <FormInputControl name='num_serie' type='text' label={'Numero de serie'} />
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                      Cerrar
                    </Button>
                    <Button colorScheme='green' type='submit'>
                      Añadir
                    </Button>
                  </ModalFooter>
                </Form>
              )
            }
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Viaje