import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCfxsxN3sCTrYbilobkXz8ROqWJbkjKe_Q",
  authDomain: "traveltrip-chile.firebaseapp.com",
  projectId: "traveltrip-chile",
  storageBucket: "traveltrip-chile.appspot.com",
  messagingSenderId: "389405229978",
  appId: "1:389405229978:web:44342d3d2183e0c4cff023"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app)
export const analytics = getAnalytics(app);