import { Box, Heading, Stack, Button } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import PasajerosTable from '../../components/pasajeros/PasajerosTable'
import SidebarWithHeader from '../../components/sidebar/Sidebar'

function Pasajeros() {
  const navigate = useNavigate()
  return (
    <SidebarWithHeader>
      <Box as="main" maxW="100%" w="100%" px={2} py={2} spacing={4}>
        <Stack align="space-between" justify="space-between" flexDir="row" spacing={4}>
          <Heading as="h1" size="xl" fontWeight="bold">
            Pasajeros
          </Heading>
          <Button onClick={() => navigate('/panel/pasajeros/crear')} colorScheme="green" size="md" rightIcon={<AiOutlineUserAdd />} style={{   marginTop: '0', }}>
            Añadir Pasajero
          </Button>
        </Stack>
      </Box>
      <PasajerosTable />
    </SidebarWithHeader>
  )
}

export default Pasajeros
