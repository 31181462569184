import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthRoute from '../components/auth/AuthRoute'
import ErrorPage from '../pages/error/ErrorPage'
import ErrorPageCliente from '../pages/error/ErrorPageCliente'
import LoginPage from '../pages/login/Login'
import CrearConductor from '../pages/panel/CrearConductor'
import Pasajeros from '../pages/panel/Pasajeros'
import InicioPage from '../pages/panel/Inicio'
import VerConductor from '../pages/panel/VerConductor'
import Viaje from '../pages/viajes/Viaje'
import CreatePasajeros from '../pages/create/CreatePasajeros'
import EditPasajeros from '../pages/panel/EditPasajeros'
import SuccessPage from '../pages/error/SuccessPage'

function Router() {
  return (
    <Routes>
        <Route path='/'>
            <Route path='login' element={<LoginPage/>}/>
            <Route path='panel'>
                <Route path='inicio' element={<AuthRoute><InicioPage /></AuthRoute>} />
                <Route path='crear-conductor'>
                  <Route path='' element={<AuthRoute><CrearConductor /></AuthRoute>} />
                  <Route path=':id' element={<AuthRoute><VerConductor /></AuthRoute>} />
                </Route>
                <Route path='pasajeros'>
                  <Route path='' element={<AuthRoute><Pasajeros /></AuthRoute>} />
                  <Route path='crear' element={<AuthRoute><CreatePasajeros /></AuthRoute>} />
                  <Route path='edit'>
                    <Route path=':rut' element={<AuthRoute><EditPasajeros /></AuthRoute>} />
                  </Route>
                </Route>
                <Route path='error' element={<AuthRoute><ErrorPage /></AuthRoute>} />
            </Route>
            <Route path='viaje'>
                <Route path=':id' element={<Viaje />} />
                <Route path='error'>
                    <Route path=':id' element={<ErrorPageCliente />} />
                </Route>
                <Route path='success'>
                    <Route path='started' element={<SuccessPage />} />
                </Route>
            </Route>

        </Route>            
    </Routes>
  )
}

export default Router