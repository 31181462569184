import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

function SuccessPage() {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bg={'green.500'}
                    rounded={'50px'}
                    w={'55px'}
                    h={'55px'}
                    textAlign="center">
                    <CheckIcon boxSize={'20px'} color={'white'} />
                </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
                Perfecto!
            </Heading>
            <Text color={'gray.500'}>
                Viaje iniciado correctamente, ya puedes cerrar esta pestaña.
            </Text>
        </Box>
    );
}

export default SuccessPage;