import { Button, Container, useToast } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Datatable from 'react-data-table-component'
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../db/config'
import { AiFillDelete, AiFillEye } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { requests } from '../../utils/axios'
function TableConductores() {
    const [conductores, setConductores] = useState([])
    const navigate = useNavigate()
    const toast = useToast({
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
        duration: 5000,
    });

    useEffect(() => {
        const getdata = async () => {
            onSnapshot(collection(db, 'drivers'), (snapshot) => {
                const data = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setConductores(data)
            })
        }
        getdata()
    }, [])
    return (
        <Container maxW={'auto'} mt='5'>
            <Datatable
                pagination={true}
                
                data={conductores}
                columns={[
                    {
                        name: 'Nombre',
                        selector: row => row.nombre,
                        sortable: true,
                    },
                    {
                        name: 'Rut',
                        selector: row => row.rut,
                        sortable: true,
                    },
                    {
                        name: 'Patente',
                        selector: row => row.patente,
                        sortable: true,
                    },
                    {
                        name: 'Telefono',
                        selector: row => row.telefono,
                        sortable: true,
                    },
                    {
                        name: 'Acciones',
                        cell: (row) => {
                            return (
                                <>
                                    <Button
                                        colorScheme='teal'
                                        size={'sm'}
                                        rightIcon={<AiFillEye />}
                                        onClick={() => {
                                            navigate('/panel/crear-conductor/' + row.id)
                                        }}
                                    >
                                        Ver
                                    </Button>
                                    <Button
                                        colorScheme='red'
                                        size={'sm'}
                                        m='3'
                                        rightIcon={<AiFillDelete />}
                                        onClick={() => {
                                            requests.post('/drivers/delete', { id: row.id }, {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                                                }
                                            }).then(() => {
                                                toast({
                                                    title: 'Conductor eliminado',
                                                    description: 'El conductor ha sido eliminado correctamente',
                                                    status: 'success',
                                                })
                                            }).catch(() => {
                                                toast({
                                                    title: 'Error',
                                                    description: 'Ha ocurrido un error al eliminar el conductor',
                                                    status: 'error',
                                                })
                                            })
                                        }}
                                    >
                                        Eliminar
                                    </Button>
                                </>
                            )
                        }
                    }
                ]}

            />
        </Container>
    )
}

export default TableConductores