import {
    Flex,
    Stack,
    Image,
} from '@chakra-ui/react';

import React from 'react'
import LoginComponent from '../../components/login/LoginComponent';

function LoginPage() {
    return (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <LoginComponent />
            </Flex>
            <Flex flex={1}>
                <Image
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={
                        '/img/camionetas.png'
                    }
                />
            </Flex>
        </Stack>
    )
}

export default LoginPage