import { Button, Container, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { collection, onSnapshot } from 'firebase/firestore'
import { useState } from 'react'
import { db } from '../../db/config'
import Datatable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { requests } from '../../utils/axios'
import { CloseIcon } from '@chakra-ui/icons'

function PasajerosTable() {
    const [pasajeros, setPasajeros] = useState([])

    const navigate = useNavigate()
    const toast = useToast({
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
        duration: 5000,
    });

    useEffect(() => {
        const getdata = async () => {
            onSnapshot(collection(db, 'pasajeros'), (snapshot) => {
                const data = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setPasajeros(data)
            })
        }
        getdata()
    }, [])

    const handleDelete = (row) => {
        requests.post('/pasajeros/delete', { id: row.id }).then(() => {
            toast({
                title: 'Pasajero eliminado',
                description: 'Se ha eliminado el pasajero correctamente',
                status: 'success',
            })
        }).catch(() => {
            toast({
                title: 'Error al eliminar pasajero',
                description: 'Ha ocurrido un error al eliminar el pasajero',
                status: 'error',
            })
        })
    }

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <InputGroup size='md'>
            <Input
                type="text"
                placeholder="Filter By Name"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={onClear}>
                    <CloseIcon />
                </Button>
            </InputRightElement>
        </InputGroup>
    );

    const [filterText, setFilterText] = React.useState('');

    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = pasajeros.filter(
        item => item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );

    }, [filterText, resetPaginationToggle]);


    return (
        <Container maxW={'auto'} mt='5'>
            <Datatable
                pagination={true}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                data={filteredItems}
                persistTableHead
                columns={[
                    {
                        name: 'Nombre',
                        selector: row => row.nombre,
                        sortable: true,
                    },
                    {
                        name: 'Rut',
                        selector: row => row.rut,
                    },
                    {
                        name: 'Numero de Serie',
                        selector: row => row.num_serie,
                    },
                    {
                        name: 'Acciones',
                        cell: row => (
                            <>
                                <Button colorScheme='red' mr={2} onClick={() => handleDelete(row)}>
                                    Eliminar
                                </Button>

                                <Button colorScheme='blue' onClick={() => navigate('/panel/pasajeros/edit/' + row.id)}>
                                    Editar
                                </Button>
                            </>
                        )
                    }
                ]}
            />
        </Container>
    )
}

export default PasajerosTable