import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

function ErrorPage() {
    const [text, setText] = useState('');
    const [searchParams] = useSearchParams();
    const err = searchParams.get('err');
    useEffect(() => {
        if (err === '404') setText('Página no encontrada');
        if (err === '401') setText('No tienes permisos para acceder a esta página');
        if (err === '500') setText('Error del servidor');
        if (err === '503') setText('Servidor no disponible');
        if (err === '400') setText('Error de solicitud');
        if (err === '403') setText('Acceso denegado');
    }, [err]);
    const navigate = useNavigate();
    return (
        <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bg={'red.500'}
                    rounded={'50px'}
                    w={'55px'}
                    h={'55px'}
                    textAlign="center">
                    <CloseIcon boxSize={'20px'} color={'white'} />
                </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
                Error!
            </Heading>
            <Text color={'gray.500'}>
                {text}
            </Text>
            <Button
                colorScheme="red"
                variant="outline"
                size="md"
                mt={4}
                onClick={() => navigate('/panel/inicio')}
            >
                Volver al inicio
            </Button>
        </Box>
    );
}

export default ErrorPage;