import {
  Heading,
  Box,
  Center,
  Text,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

export default function AdminCard({ data }) {
  return (
      <Center py={6} mt='3'>
          <Box
              maxW={'300px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.800')}
              rounded={'md'}
              overflow={'hidden'}>
              <Box p={6}>
                  <Stack spacing={0} align={'center'} mb={5}>
                      <Heading fontSize={'2xl'} fontWeight={500} textAlign={'center'} fontFamily={'body'}>
                          {data.nombre}
                      </Heading>
                  </Stack>

                  <Stack direction={'row'} justify={'center'} spacing={6}>
                      <Stack spacing={0} align={'center'}>
                          <Text fontWeight={600}>{data.rut}</Text>
                          <Text fontSize={'sm'} color={'gray.500'}>
                              RUT
                          </Text>
                      </Stack>
                      <Stack spacing={0} align={'center'}>
                          <Text fontWeight={600}>{data.num_serie}</Text>
                          <Text fontSize={'sm'} color={'gray.500'}>
                              Numero de Serie
                          </Text>
                      </Stack>
                  </Stack>
              </Box>
          </Box>
      </Center>
  );
}