import {
  Center, Heading, Stack, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, FormControl, FormLabel, useToast,
} from '@chakra-ui/react'
import { AiFillFileExcel } from 'react-icons/ai'
import SidebarWithHeader from '../../components/sidebar/Sidebar'
import { addDays } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import es from 'date-fns/locale/es';
import { useState } from 'react';
import { Select } from "chakra-react-select";
import { requests } from '../../utils/axios'

function InicioPage() {
  const { isOpen, onClose } = useDisclosure()
  const [range, setRange] = useState()
  const pastMonth = addDays(new Date(), 4);
  const [getDrivers, setGetDrivers] = useState([]);
  const [getDriver, setGetDriver] = useState([]);
/* 
  const getData = async () => {
    await requests.get('/drivers/getall', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }).then((res) => {
      setGetDrivers(res.data.data)
      console.log(res.data);
    })
  } */

  const onOpenModal = async () => {
    /*     await getData()
    onOpen() */
    toast({
      title: 'Error',
      description: 'En construcción, no disponible',
      status: 'error',
    })
  }

  const toast = useToast({
    position: 'top-right',
    variant: 'left-accent',
    isClosable: true,
    duration: 5000,
  })


  const onsenddata = async (values) => {
    await requests.post('/excel/create', {
      id_conductor: getDriver,
      fecha_inicio: range.from,
      fecha_fin: range.to,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }).then((res) => {
      toast({
        title: 'Excel creado',
        description: 'El excel se ha creado correctamente',
        status: 'success',
      })
      window.open(res.data.data, '_blank')
    }).catch((err) => {
      if (err.response.status === 404) {
        toast({
          title: 'Error',
          description: 'No se encontro datos de esos dias.',
          status: 'error',
        })
      }
    })
  }

  return (
    <SidebarWithHeader>
      <Heading>
        Inicio
      </Heading>
      <Center mt={3}>
        <Stack bg={'white'} width={'30%'} height={'auto'} rounded={'xl'} dropShadow={'2xl'}>
          <Heading my={3} textAlign='center'>
            Panel Botones
          </Heading>
          <Stack mt={3} spacing={3} direction={'row'} justify={'center'}>
            <Button colorScheme='green' variant={'outline'} rightIcon={<AiFillFileExcel />} mb='6' onClick={onOpenModal}>
              Generar Excel por periodo de tiempo
            </Button>
          </Stack>
        </Stack>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generar Reporte en Excel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Elige el rango de fechas para sacar un excel del conductor.</FormLabel>
              <Center>
                <DayPicker
                  locale={es}
                  mode="range"
                  defaultMonth={pastMonth}
                  selected={range}
                  onSelect={setRange}
                />
              </Center>
            </FormControl>
            <FormControl>
              <FormLabel>
                Elige el conductor del cual deseas sacar el reporte
              </FormLabel>

              <Select
                options={getDrivers}
                placeholder="Selecciona a el conductor..."
                closeMenuOnSelect={false}
                onChange={(e) => setGetDriver(e.value)}
                selectedOptionColor="green"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme='green' onClick={onsenddata}>Descargar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </SidebarWithHeader>
  )
}

export default InicioPage