import { Box, Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import SidebarWithHeader from '../../components/sidebar/Sidebar'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { Formik } from 'formik'
import FormInputControl from '../../components/input/FormInputControl';
import * as Yup from 'yup';
import { requests } from '../../utils/axios'
import TableConductores from '../../components/crearconductor/TableConductores'

function CrearConductor() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast({
    position: 'top-right',
    variant: 'left-accent',
    isClosable: true,
    duration: 5000,
  });

  const sendData = async (values) => {
    await requests.post('/drivers/add', values, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      }
    }).then(res => {
      toast({
        title: 'Exito',
        description: 'Se ha creado el conductor',
        status: 'success',
      })
    }).catch(err => {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    })
  }
  return (
    <SidebarWithHeader>
      <Box
        as="main"
        maxW="100%"
        w="100%"
        px={2}
        py={2}
        spacing={4}
      >
        <Stack
          align="space-between"
          justify="space-between"
          flexDir="row"
          spacing={4}
        >
          <Heading
            as="h1"
            size="xl"
            fontWeight="bold"
          >
            Crear Conductor
          </Heading>
          <Button
            colorScheme="green"
            size="md"
            rightIcon={<AiOutlineUserAdd />}
            style={{
              marginTop: '0',
            }}
            onClick={onOpen}
          >
            Añadir
          </Button>
        </Stack>
      </Box>

      <TableConductores />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              nombre: '',
              rut: '',
              patente: '',
              telefono: '',
            }}
            validationSchema={Yup.object({
              nombre: Yup.string()
                .required('El nombre es requerido'),
              rut: Yup.string()
                .required('El rut es requerido')
                .max(9, 'El rut es muy largo'),
              patente: Yup.string()
                .required('La patente es requerida')
                .max(6, 'La patente es muy larga'),
              telefono: Yup.string()
                .required('El telefono es requerido'),
            })}

            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              await sendData(values)
              onClose()
              actions.setSubmitting(false);
            }}
          >
            {(formik) => (
              <>
                <ModalHeader>Añadir conductor</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormInputControl name='nombre' type='text' placeholder='Pon el nombre completo del conductor' label={'Nombre del conductor'} />
                  <FormInputControl name='rut' type='text' placeholder='Pon el rut del conductor' label={'Rut del conductor'} />
                  <FormInputControl name='patente' type='text' placeholder='Pon la patente del conductor' label={'Patente del conductor'} />
                  <FormInputControl name='telefono' type='tel' placeholder='Pon el telefono del conductor' label={'Telefono del conductor'} />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme='green' mr={3} onClick={formik.handleSubmit} isLoading={formik.isSubmitting}>
                    Guardar
                  </Button>
                  <Button colorScheme='red' onClick={onClose} isLoading={formik.isSubmitting}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </SidebarWithHeader>
  )
}

export default CrearConductor