import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { Field, useField } from 'formik'
import React from 'react'

function FormInputControl({ label, ...props }) {
    const [field, meta] = useField(props)
    return (
        <FormControl isInvalid={meta.error && meta.touched} mt='3'>
            <FormLabel>{label}</FormLabel>
            <Field
                as={Input}
                {...field}
                {...props}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}

export default FormInputControl