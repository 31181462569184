import { Box, Button, Center, Heading, Stack, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import SidebarWithHeader from '../../components/sidebar/Sidebar'
import FormInputControl from '../../components/input/FormInputControl';
import * as Yup from 'yup'
import { requests } from '../../utils/axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

function EditPasajeros() {
    const toast = useToast({
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
        duration: 5000,
    });
    const navigate = useNavigate();
    const { rut } = useParams();
    const [pasajero, setPasajero] = React.useState({})
    useEffect(() => {
        requests.post('/pasajeros/get', {
            run: rut
        }).then((res) => {
            setPasajero(res.data.data)
        })
    }, [rut])
    return (
        <SidebarWithHeader>
            <Heading>
                Editar Pasajeros
            </Heading>
            <Center>
                <Box
                    mt={3}
                    w="full"
                    maxW="2xl"
                    bg="white"
                    boxShadow="md"
                    rounded="md"
                    overflow="hidden"
                    p={3}
                >
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            nombre: pasajero.nombre,
                            rut: pasajero.rut,
                            num_serie: pasajero.num_serie
                        }}
                        validationSchema={
                            Yup.object({}).shape({
                                nombre: Yup.string().required('Nombre es requerido').matches(/^[a-zA-Z ]*$/, 'Solo se permiten letras'),
                                rut: Yup.string().required('Rut es requerido').matches(/^[0-9]+-[0-9kK]{1}$/, 'Rut no valido'),
                                num_serie: Yup.string().required('Numero de serie es requerido').matches(/^[0-9]{1,}$/, 'Solo se permiten numeros').max(9, 'Maximo 9 digitos')
                            })
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false)
                            requests.post('/pasajeros/update', {
                                ...values,
                                id: rut
                            }).then(() => {
                                toast({
                                    title: 'Pasajero editado',
                                    description: 'Se ha editado el pasajero correctamente',
                                    status: 'success',
                                })
                                navigate('/panel/pasajeros')
                            }).catch(() => {
                                toast({
                                    title: 'Error al editar pasajero',
                                    description: 'Ha ocurrido un error al editar el pasajero',
                                    status: 'error',
                                })
                            })
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form>
                                <FormInputControl name='nombre' type='text' placeholder='Pon el nombre de la persona' label={'Nombre'} />
                                <FormInputControl name='rut' type='text' placeholder='Pon el RUT de la persona' label={'RUT'} />
                                <FormInputControl name='num_serie' type='text' placeholder='Pon el NUM de serie del carnet' label={'Numero de Serie'} />

                                <Stack>
                                    <Button mt={3} type='submit' colorScheme='yellow' isDisabled={isSubmitting} isLoading={isSubmitting} loadingText='Cargando...'>
                                        Editar
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Center>
        </SidebarWithHeader>
    )
}

export default EditPasajeros