import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

function AuthRoute({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
  }, [user, navigate]);

  if (user) {
    return children
  }
}

export default AuthRoute