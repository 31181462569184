import React from "react";
import ReactDOM from "react-dom/client";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Router from "./router/Router";
import "react-datepicker/dist/react-datepicker.css";
import { PasajerosProvider } from "./contexts/PasajerosContext";

const theme = extendTheme({
  fonts: {
    body: "Raleway, sans-serif",
    heading: "Raleway, sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <PasajerosProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </PasajerosProvider>
    </AuthProvider>
  </ChakraProvider>
);
