import { Badge, Button, Container, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { collection, onSnapshot } from 'firebase/firestore'
import { useEffect } from 'react'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { db } from '../../db/config'
import { requests } from '../../utils/axios'
import AdminCard from './AdminCard'

function TableConductor({ id }) {
    const toast = useToast({
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
        duration: 5000,
    });

    const [conductor, setConductor] = useState([])
    const [pasajeroModal, setPasajeroModal] = useState([])
    
    useEffect(() => {
        const getdata = async () => {
            onSnapshot(collection(db, 'viajes'), (snapshot) => {
                //map only documents with same id as conductor id
                const data = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                //filter only documents with same id as conductor id
                const dataFiltered = data.filter(doc => doc.idConductor === id)
                // only show todays data
                const dataToday = dataFiltered.filter(doc => {
                    const date = new Date(doc.horaCreacion)
                    const today = new Date()
                    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
                })
                setConductor(dataToday)
            })
        }
        getdata()
    }, [id])

    const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Container maxW={'auto'} mt='5'>
        <DataTable
        columns={[
            { name: 'Patente', selector: row => row.patente, sortable: false },
            { name: 'Hora del viaje', selector: row => new Date(row.travelHour).toLocaleString(), sortable: false },
            { name: 'Hora de apertura de link', selector: row => new Date(row.horaAperturaLink).toLocaleString(), sortable: false },
            { name: 'Estado del link', selector: row => (
                row.opened === true ? <Badge colorScheme='green'>Abierto</Badge> : <Badge colorScheme='red'>Aun no abierto</Badge>
            ) , sortable: false },
            { name: 'Acciones', cell: (row) => {
                return (
                    <>
                        <Button
                            colorScheme='blue'
                            m={2}
                            size={'sm'}
                            onClick={async () => {
                                setPasajeroModal(row.pasajeros)
                                onOpen()
                            }}
                        >
                            Ver Pasajeros
                        </Button>
                        <Button
                            colorScheme='red'
                            size={'sm'}
                            onClick={() => {
                                requests.post('/links/delete', { id: row.id }, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                                    }
                                }).then(() => {
                                    toast({
                                        title: 'Viaje eliminado',
                                        description: 'El viaje ha sido eliminado correctamente',
                                        status: 'success',
                                    })
                                }).catch(() => {
                                    toast({
                                        title: 'Error',
                                        description: 'Ha ocurrido un error al eliminar el viaje',
                                        status: 'error',
                                    })
                                })
                            }}
                        >
                            Eliminar Link
                        </Button>
                    </>
                )
            }, sortable: true },
        ]}
        title="Viajes"
        pagination={true}
        data={conductor}
    />
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Pasajeros en el viaje</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack
                    flexDir='column'
                >
                {
                    pasajeroModal.map((pasajero, index) => {
                        return (
                            <AdminCard data={pasajero} key={index} />
                        )
                    })
                }
                </Stack>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>

    </Container>
  )
}

export default TableConductor