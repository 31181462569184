import { Box, Button, Heading, Stack, useDisclosure, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, Center, } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import TableConductor from '../../components/crearconductor/TableConductor'
import SidebarWithHeader from '../../components/sidebar/Sidebar'
import { requests } from '../../utils/axios'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import es from 'date-fns/locale/es';

function VerConductor() {
    const todaysDate = new Date()
    const hoursAndMinutes = todaysDate.getHours() + ':' + todaysDate.getMinutes();

    const [selectedDate, setSelectedDate] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { id } = useParams()
    const navigate = useNavigate()
    const [travelHour, setTravelHour] = useState(hoursAndMinutes)

    const toast = useToast({
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
        duration: 5000,
    });

    const [conductor, setConductor] = useState([])
    useEffect(() => {
        const getdata = async () => {
            await requests.get('/drivers/get/' + id, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(res => {
                setConductor(res.data.data)
            }).catch(err => {
                if (err.response.status === 404) {
                    navigate('/panel/error?err=404')
                }
            })
        }
        getdata()
    }, [id, navigate])

    const handleCrearViaje = async () => {
        if (selectedDate === undefined) {
            return toast({
                title: 'Seleccione una fecha',
                description: 'Por favor seleccione una fecha para el viaje',
                status: 'error'
            })
        }


        const dateTravel = new Date(selectedDate)
        dateTravel.setHours(travelHour.split(':')[0])
        dateTravel.setMinutes(travelHour.split(':')[1])
        dateTravel.setSeconds(0)

        requests.post('/links/create', {
            idConductor: id,
            nombre: conductor.nombre,
            rut: conductor.rut,
            patente: conductor.patente,
            horaDelViaje: dateTravel,
            telefono: conductor.telefono
        }, {
            headers: {
                "Application": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(res => {
            console.log(res);
           if (res.data) {
               toast({
                   title: 'Viaje creado',
                   description: 'El viaje ha sido creado correctamente',
                   status: 'success'
               })
               navigator.clipboard.writeText(res.data.url)
            }
        }).catch(err => {
            if (err.response.status === 500) {
                navigate('/panel/error?err=500')
            } else if (err.response.status === 400) {
                toast({
                    title: 'Error',
                    description: 'No puedes crear un viaje con una fecha anterior a la actual',
                    status: 'error'
                })
            }
        })
    }

    return (
        <SidebarWithHeader>
            <Box
                as='main'
                maxW='100%'
                w='100%'
                px={2}
                py={2}
                spacing={4}
            >
                <Stack
                    align='space-between'
                    justify='space-between'
                    flexDir='row'
                    spacing={4}
                >
                    <Heading>
                        {conductor.nombre}
                    </Heading>
                    <Stack
                        flexDir={'row'}

                    >
                        <Button
                            colorScheme='blue'
                            variant='outline'
                            size='md'
                            style={{
                                marginTop: '0',
                            }}
                            onClick={onOpen}
                            rightIcon={<AiOutlineUserAdd />}
                        >
                            Crear Link de Viaje
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <TableConductor id={id} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Crear Viaje</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <FormControl>
                            <FormLabel>Elige la fecha en la que se realizara el viaje</FormLabel>


                            <Center>
                                <DayPicker
                                    onDayClick={(day, { selected }) => {
                                        setSelectedDate(day)
                                    }
                                    }
                                    disabled={day => {
                                        // disable all days before today and disable all days after 1 day from today
                                        return day < new Date(new Date().setDate(new Date().getDate() -1)) || day > new Date(new Date().setDate(new Date().getDate() + 1))
                                    }}
                                    locale={es}
                                    selected={selectedDate}
                                    mode={'single'}
                                    disableNavigation
                                />
                            </Center>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Elige la hora a la que se realizará el viaje</FormLabel>

                            <Input
                                placeholder="Select Date and Time"
                                size="md"
                                type="time"
                                value={travelHour}
                                onChange={(e) => setTravelHour(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='green' mr={3} onClick={() => {
                            handleCrearViaje()
                            onClose()
                        }}>Crear</Button>
                        <Button colorScheme='red' onClick={onClose}>
                            Cerrar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </SidebarWithHeader>
    )
}

export default VerConductor