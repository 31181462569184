import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

function ErrorPage() {
    const [text, setText] = useState('');
    const {id: err} = useParams();
    useEffect(() => {
        if (err === '404') setText('URL no encontrada');
        if (err === 'opened') setText('Este link ya fue abierto!');
        if (err === '500') setText('Error del servidor');
        if (err === 'tiempo') setText('Estas fuera de tiempo para abrir este link!');
    }, [err]);
    return (
        <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bg={'red.500'}
                    rounded={'50px'}
                    w={'55px'}
                    h={'55px'}
                    textAlign="center">
                    <CloseIcon boxSize={'20px'} color={'white'} />
                </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
                Error!
            </Heading>
            <Text color={'gray.500'}>
                {text}
            </Text>
            <Button
                colorScheme="red"
                variant="outline"
                size="md"
                mt={4}
                onClick={() => window.history.back()}
            >
                Volver
            </Button>
        </Box>
    );
}

export default ErrorPage;