import { createContext, useState, useContext } from "react";

const PasajerosContext = createContext();

export const usePasajeros = () => {
    return useContext(PasajerosContext);
};

export const PasajerosProvider = ({ children }) => {
    const [pasajeros, setPasajeros] = useState([]);

    const addPasajero = (pasajero) => {
        setPasajeros([...pasajeros, pasajero]);
    };

    const removePasajero = (pasajero) => {
        setPasajeros(pasajeros.filter((p) => p.rut !== pasajero.rut));
    };

    const getPasajeros = () => {
        return pasajeros;
    };


    return (
        <PasajerosContext.Provider value={{addPasajero, removePasajero, getPasajeros}}>
            {children}
        </PasajerosContext.Provider>
    );
}