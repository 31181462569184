import {
    Heading,
    Box,
    Center,
    Text,
    Stack,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import { usePasajeros } from '../../contexts/PasajerosContext';
export default function ViajesCard({ data }) {
    const pasajeros = usePasajeros()
    return (
        <Center py={6}>
            <Box
                maxW={'270px'}
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                boxShadow={'2xl'}
                rounded={'md'}
                overflow={'hidden'}>
                <Box p={6}>
                    <Stack spacing={0} align={'center'} mb={5}>
                        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                            {data.nombre}
                        </Heading>
                    </Stack>

                    <Stack direction={'row'} justify={'center'} spacing={6}>
                        <Stack spacing={0} align={'center'}>
                            <Text fontWeight={600}>{data.rut}</Text>
                            <Text fontSize={'sm'} color={'gray.500'}>
                                RUT
                            </Text>
                        </Stack>
                        <Stack spacing={0} align={'center'}>
                            <Text fontWeight={600}>{data.num_serie}</Text>
                            <Text fontSize={'sm'} color={'gray.500'}>
                                Numero de Serie
                            </Text>
                        </Stack>
                    </Stack>

                    <Button
                        w={'full'}
                        mt={8}
                        bg={'#151f21'}
                        color={'white'}
                        rounded={'md'}
                        onClick={() => {
                            pasajeros.removePasajero(data)
                        }}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}>
                        Eliminar
                    </Button>


                </Box>
            </Box>
        </Center>
    );
}